import * as React from 'react';
import { Link, graphql } from 'gatsby';
import {
  Layout,
  SEO,
  CRestaurantJumbotron,
  CBreadCrumb,
  LWrap,
  Search,
  LRestaurantPickup,
  CHeroMedia,
  CSectHeading,
  CCard,
  CHeroImg,
  CSpSliderCard,
  CSectTitle,
  CBtnList,
  LMenu,
  LRestaurant,
  CFadeSlider,
  ShopContents,
  LRestaurantContact,
} from '../../../components/_index';
import shopGet from '../../../utils/shop-get';
import { shopFixedChoice } from '../../../utils/shop-choice';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CRestaurantJumbotron
        size="large"
        data={{
          location: {
            floor: 'B1F',
            category: '鉄板焼',
          },
          logo: {
            src: '/assets/images/restaurants/yokohama/logo_top.svg',
            alt: '',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/yokohama/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/yokohama/kv__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/yokohama/kv02.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/yokohama/kv02__sp.png',
              },
            },
            {
              img: {
                src: '/assets/images/restaurants/yokohama/kv03.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/yokohama/kv03__sp.png',
              },
            },
          ],
        }}
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
            ],
            current: {
              label: 'B1F 鉄板焼 よこはま',
            },
          }}
        />
      </CRestaurantJumbotron>
      <Search
        formId="toppage"
        currentIndex={1}
        selected="yrph-teppannyaki-yokohama"
      />
      <section className="l_sect">
        <LWrap>
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_media.png',
                  alt: '',
                },
                title: <>横浜の地名を冠に</>,
                text: (
                  <>
                    「ドッグヤードガーデン」に面した店内は、「海底」をイメージしたブルーの絨毯が印象的。個性的なインテリアは、落ち着いた雰囲気をかもし出します。
                  </>
                ),
              },
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_media02.png',
                  alt: '',
                },
                title: <>全席カウンターの鉄板焼レストラン</>,
                text: (
                  <>
                    目の前の鉄板上で繰り広げられるシェフのパフォーマンス、食欲をそそられる音と香りが至福の時間をお届けします。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mbLarge">
        <LWrap>
          <CSectHeading
            data={{
              title: (
                <>
                  ORIGINAL <br className="u_sp" />
                  MENU
                </>
              ),
              text: (
                <>
                  黒毛和牛や各地の
                  <br />
                  銘柄牛、魚介類を
                  <br />
                  目の前で
                </>
              ),
            }}
          />
          <CHeroImg
            exClass="u_mb40"
            img={{
              src: '/assets/images/restaurants/yokohama/img_originalmenu.png',
              alt: '',
            }}
          />
          <CSpSliderCard
            exClass="u_mb40"
            col={3}
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_originalmenu02.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_originalmenu03.png',
                  alt: '',
                },
              },
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_originalmenu04.png',
                  alt: '',
                },
              },
            ]}
          />
          {/* <div className="u_centerring">
            <CFadeSlider
              exClass="u_mb40"
              data={[
                {
                  img: {
                    src: '/assets/images/restaurants/yokohama/img_originalmenu.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yokohama/img_originalmenu02.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yokohama/img_originalmenu03.png',
                    alt: '',
                  },
                },
                {
                  img: {
                    src: '/assets/images/restaurants/yokohama/img_originalmenu04.png',
                    alt: '',
                  },
                },
              ]}
            />
          </div> */}
          <CHeroMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/yokohama/img_media03.png',
                  alt: '',
                },
                title: <></>,
                text: (
                  <>
                    黒毛和牛や各地の銘柄牛、魚介類を目の前で仕上げるシェフの見事な手さばきを独り占め。
                    <br />
                    神奈川県産の野菜をはじめとした、こだわり抜いた食材をご堪能いただけます。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section>
        <LWrap>
          <CSectTitle
            title={{
              en: 'RESTAURANT',
              ja: '店舗情報',
            }}
          />
          <ShopContents
            data={shopFixedChoice(shopGet(), 'yokohama_top').contents1}
          />
        </LWrap>
      </section>
      <LMenu
        data={[
          {
            img: {
              src: '/assets/images/restaurants/yokohama/img_menu.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/yokohama/img_menu__sp.png',
            },
            title: <>LUNCH</>,
            btn: {
              label: <>ランチ</>,
              link: {
                href: '/restaurants/yokohama/menu/#lunch',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/yokohama/img_menu02.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/yokohama/img_menu__sp02.png',
            },
            title: <>DINNER</>,
            btn: {
              label: <>ディナー</>,
              link: {
                href: '/restaurants/yokohama/menu/#dinner',
              },
            },
          },
          {
            img: {
              src: '/assets/images/restaurants/yokohama/img_menu03.png',
            },
            imgSp: {
              src: '/assets/images/restaurants/yokohama/img_menu__sp03.png',
            },
            title: <>DRINK</>,
            btn: {
              label: <>ドリンク</>,
              link: {
                href: '/restaurants/yokohama/menu/#drink',
              },
            },
          },
        ]}
      />
      <CBtnList
        data={[
          {
            label: 'すべてのメニューを見る',
            link: {
              href: '/restaurants/yokohama/menu/',
            },
          },
        ]}
      />
      <LRestaurantPickup restaurant={['よこはま']} />
      <section className="l_sect">
        <LWrap>
          <CSectTitle title={{ ja: 'プラン', en: 'PLAN' }} />
          <CCard
            data={[
              {
                link: {
                  href: '/anniversary/restaurants_plan/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan.png',
                  alt: '',
                },
                title: <>お祝い・記念日</>,
                content: (
                  <>
                    レストランでの特別なひとときに、より華を添えるプランをご用意しました。心を込めたお料理とおもてなしで素敵な時間を演出いたします。
                  </>
                ),
              },
              {
                link: {
                  href: '/anniversary/restaurants_plan/celebration/',
                },
                img: {
                  src: '/assets/images/restaurants/img_plan03.png',
                  alt: '',
                },
                title: <>パーティープラン</>,
                content: (
                  <>
                    納涼会や歓迎会など、パーティーに最適なプランをご紹介いたします。
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="u_mt90">
        <LRestaurant />
      </section>
      <section className="l_sect">
        <LWrap>
          <ul className="c_noteList">
            <li>画像は全てイメージです。</li>
            <li>料金には消費税、サービス料が含まれます。</li>
            <li>料理の表示価格は、特別な記載がない限り1名様分の料金です。</li>
            <li>
              テイクアウト商品の料金には消費税（8％）のみ含まれます。コフレ店内をご利用の場合は、サービス料を加算、消費税は10％となります。
            </li>
            <li>入荷状況によりメニュー内容に変更がある場合がございます。</li>
            <li>
              「神奈川県公共的施設における受動喫煙防止条例」に基づきレストラン・バーを全席禁煙とさせていただいております。
            </li>
            <li>
              ご入店の際は、タンクトップ・短パン・ビーチサンダルなどはご遠慮いただいております。
            </li>
          </ul>
        </LWrap>
      </section>
      <LRestaurantContact selected="yrph-teppannyaki-yokohama" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
